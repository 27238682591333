import { ButtonHTMLAttributes, useState } from "react"
import ReactDOM from "react-dom"
import "./Modal.scss"

const MODAL_ROOT_ID = "modal-root"

interface ModalProps {
    onClose: () => void
    children: (close: () => void) => React.ReactNode
    closed?: boolean
}

export default function Modal({ onClose, children, closed }: ModalProps)
{
    return closed ? null : ReactDOM.createPortal(
        children(onClose),
        document.getElementById(MODAL_ROOT_ID)!
    );
}

interface ModalButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    /**
     * Initially open the modal dialog
     */
    open?: boolean

    /**
     * The modal dialog contents
     */
    dialog: (close: () => void) => React.ReactNode
}

export function ModalButton({ children, dialog, open, onClick, ...rest }: ModalButtonProps)
{
    const [isOpen, setIsOpen] = useState(!!open)

    const _onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsOpen(true);
        if (onClick) {
            onClick(e)
        }
    }

    return (
        <>
            <button onClick={_onClick} { ...rest }>{ children }</button>
            <Modal closed={!isOpen} onClose={() => setIsOpen(false)}>{ dialog }</Modal>
        </>
    )
}
