import React from "react";

export default class Title extends React.Component
{
    componentDidMount()
    {
        document.title = String(this.props.children);
    }

    shouldComponentUpdate()
    {
        return false;
    }

    render()
    {
        return null;
    }
}