import { useEffect }   from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { RootState }   from '../store'
import { logout }      from "../store/auth"


export default function Logout()
{
    const { currentUser } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            // @ts-ignore
            dispatch(logout());
        } else {
            navigate("/")
        }
    }, [currentUser, navigate, dispatch])

    return (
        <div className="text-center">
            <br/>
            <i className="fas fa-spinner fa-spin"/> Please wait...
        </div>
    );
}
