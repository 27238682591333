import { useState }    from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { Dispatch }    from "redux"
import * as lib        from "../../lib"
import { RootState }   from "../../store"
import Test            from "../Test/index"
import { runNode, TestsList, TestsListItem } from "../../store/tests"
import "./TestGroup.scss"


export default function TestGroupComponent({ item }: { item: TestsListItem })
{
    const { loading, tests, running } = useSelector((state: RootState) => state.tests)
    const { selectedId } = useSelector((state: RootState) => state.servers)

    const [opened, setOpened] = useState(true);
    const dispatch = useDispatch<Dispatch<any>>()

    if (loading) {
        return <div>Loading...</div>
    }

    if (!selectedId) {
        return <div>No server selected</div>
    }

    if (!item.path) {
        return <TestChildren node={item} tests={tests} />
    }

    return (
        <div className={
            lib.className({
                "test-group": 1,
                closed: !opened
            })
        }>
            <header className="test-group-header" onClick={() => setOpened(!opened)}>
                <i className={"fas fa-caret-" + (opened ? "down" : "right")}/>
                <i className={"far fa-folder" + (opened ? "-open" : "")}/>
                <span className="name">{ item.name }</span>
                { !running && <span className="run-button" onClick={ (e) => {
                    e.stopPropagation();
                    dispatch(runNode(item.path, selectedId));
                }}>Run ▶︎</span> }
            </header>
            <div className="children">
                <TestChildren node={item} tests={tests}/>
            </div>
        </div>
    )
}

function TestChildren({ node, tests }: { node: TestsListItem, tests: TestsList }) {
    const children = Object.keys(tests)
        .filter(key => tests[key].pid === node.path && tests[key].path !== node.path)
        .map(key => tests[key]);

    
    if (!children) {
        return null;
    }

    return (
        <div>{ children.map((o, i) => o.type === "group" ?
                <TestGroupComponent item={o} key={i}/> :
                <Test item={o} key={i}/>
        )}</div>
    );
}


