import { useSelector } from "react-redux"
import { NavLink }     from "react-router-dom"
import { RootState }   from "../../store"
import "./Footer.scss"



export default function AppFooter()
{
    const { currentUser } = useSelector((state: RootState) => state.auth)
    
    return (
        <footer className="app-footer">
            <div className="app-footer-inner">
                <a target="_blank" rel="noopener noreferrer" href="http://www.childrenshospital.org/">
                    &copy; Boston Children's Hospital
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/smart-on-fhir/bdt">
                    GitHub
                </a>
                { currentUser && <NavLink to="/logout">Logout { currentUser.username }</NavLink> }
            </div>
        </footer>
    );
}
