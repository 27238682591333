import { useState }    from "react"
import { useDispatch } from "react-redux"
import Form            from "../Servers/Form"
import { loadAvailableServers, saveServer }  from "../../store/servers"
import { Server }      from "../../.."


export function CreateServerDialog({ close }: { close: () => void })
{
    const [ state, setState ] = useState<Server>({ isLocal: true })
    const dispatch = useDispatch<any>()

    return <Form
            server={ state }
            local={ true }
            onChange={ setState }
            onSubmit={ async () => {
                dispatch(saveServer(state))
                dispatch(loadAvailableServers())
                close()
            }}
            close={ close }
        />
}
