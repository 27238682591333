import { ChangeEvent, useEffect } from "react"
import { useDispatch }            from "react-redux"
import { useNavigate, useParams } from "react-router"
import { useSelector }            from "react-redux"
import { Dispatch }               from "redux"
import { setSelectedServerId }    from "../../store/servers"
import { runNode }                from "../../store/tests"
import { RootState }              from "../../store"
import Progress                   from "../Progress"
import TestGroupComponent         from "../TestGroup"
import { ModalButton }            from "../Modal"
import Title                      from "../Title"
import RegisterDialog             from "../Dialogs/RegisterServer"
import DeleteServerDialog         from "../Dialogs/DeleteServer"
import Loader                     from "../Loader"
import { CreateServerDialog }     from "../Dialogs/CreateServer"
import { UpdateServerDialog }     from "../Dialogs/UpdateServer"
import { StoredServer }           from "../../.."
import {
    reset,
    cancel,
    setBulkDataVersion
} from "../../store/tests"
import "./TestSuite.scss"
import { Link } from "react-router-dom"


const BulkDataVersions = ["1.0", "2.0"];


function APIVersionSelector() {
    const dispatch = useDispatch()
    const { running, bulkDataVersion } = useSelector((state: RootState) => state.tests)
    
    return (
        <div className="col">
            <div>
                Bulk Data API:&nbsp;<select
                disabled={running}
                onChange={(e) => dispatch(setBulkDataVersion(e.target.value))}
                value={bulkDataVersion}
                >
                    { BulkDataVersions.map(v => <option key={v} value={v}>v{v}</option>) }
                </select>
            </div>
        </div>
    );
}

function ServerSelector() {

    const navigate = useNavigate()

    const { selectedId, items, loading, error } = useSelector((state: RootState) => state.servers)

    const disabled = useSelector((state: RootState) => state.tests.running)

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{ String(error || "") }</p>;
    }

    function renderOnlineServers()
    {
        const servers = items.filter(o => !o.isLocal);
        if (!servers.length) {
            return null;
        }
        return (
            <optgroup label="Registered Online Servers">
                { servers.map(s => (
                    <option key={s.id} value={s.id}>{s.name}</option>    
                ))}
            </optgroup>
        );
    }

    function renderOfflineServers()
    {
        const servers = items.filter(o => o.isLocal);
        if (!servers.length) {
            return null;
        }
        return (
            <optgroup label="My Local Servers">
                { servers.map(s => (
                    <option key={s.id} value={s.id}>{s.name}</option>    
                ))}
            </optgroup>
        );
    }

    function onChange(e: ChangeEvent<HTMLSelectElement>)
    {
        const id = e.target.value
        navigate(id ? `/tests/server/${id}` : "/tests")
    }

    return (
        <div className="col">
            <div>
                Server:&nbsp;<select
                disabled={disabled}
                value={selectedId || ""}
                onChange={onChange}>
                    <option value="">No Server Selected</option>
                    { renderOnlineServers() }
                    { renderOfflineServers() }
                </select>
            </div>
        </div>
    )
}

function TestSuiteHome() {
    return (
        <div className="home-screen">
            <div>
                <div>
                    <h1>Select a Server</h1>
                    <br/>
                    <p style={{ textAlign: "justify" }}>
                        We are maintaining a list of publicly available Bulk Data servers.
                        You can start by selecting one of them and testing it using our test suite
                        against it.
                    </p>
                    <br/>
                    <hr/>
                    <br/>
                    <p>
                        <Link to="/servers" className="btn btn-primary"><b>Browse Servers</b></Link>
                    </p>
                </div>
                <div>
                    <div>
                        <h1>Test Your Server</h1>
                        <br/>
                        <p style={{ textAlign: "justify" }}>
                            You can use this tool to test your own Bulk Data server.
                            To do so you can start by adding your server configuration.
                            It will be stored locally in this browser and nobody else
                            will be able to see it.
                        </p>
                        <br/>
                        <hr/>
                        <br/>
                        <p>
                            <ModalButton
                                className="btn btn-primary"
                                dialog={ close => <CreateServerDialog close={close} /> }
                            ><b>Add Server Config</b></ModalButton>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function TestSuite()
{
    const params = useParams()
    const dispatch = useDispatch<Dispatch<any>>()
    const { servers, auth, tests } = useSelector((state: RootState) => state)
    const disabled = tests.running
    const { currentUser } = auth
    const id = params.id || null
    const { selectedId, loading } = servers

    useEffect(() =>  {
        dispatch(reset());
        dispatch(setSelectedServerId(id ? +id : null));
        return () => { dispatch(cancel(true)) }
    }, [id, dispatch])

    const renderRunBtn = () =>
    {
        const text = tests.running ?
            <>Stop Testing <i className="fas fa-stop"/></> :
            <>Run All Tests <i className="fas fa-play"/></>;

        const onClick = () => {

            if (tests.running) {
                dispatch(cancel(true));
            } else {
                if (selectedId) {
                    dispatch(runNode("", selectedId));
                }
            }
        };

        return (
            <button
                className={ "btn run-all-btn" + (tests.running ? " btn-primary" : " btn-success") }
                disabled={ !selectedId }
                onClick={ onClick }
            >{ text }</button>
        );
    }

    if (loading || !Object.keys(tests.tests).length) {
        return <Loader />;
    }

    const selectedServer = selectedId ?
        servers.items.find(s => +s.id! === selectedId) :
        null;
    
    return (
        <>
            <header className="tests-toolbar">
                <div className="container" style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <div className="text-center toolbar">
                        <ServerSelector />
                        <APIVersionSelector/>
                        <div className="col">
                            { renderRunBtn() }
                        </div>

                        { selectedServer && (currentUser || selectedServer.isLocal) && (
                            <>
                                <div className="col">
                                    <ModalButton
                                        className="btn btn-secondary"
                                        disabled={disabled}
                                        dialog={close => <UpdateServerDialog server={ selectedServer as StoredServer } close={ close } />}
                                    >Edit</ModalButton>
                                </div>
                                <div className="col">
                                    <ModalButton
                                        className="btn btn-secondary"
                                        disabled={disabled}
                                        dialog={close => <DeleteServerDialog server={ selectedServer } close={ close } />}
                                    >Delete</ModalButton>
                                </div>
                            </>
                        ) }

                        { selectedServer?.isLocal && 
                            <div className="col">
                                <ModalButton
                                    className="btn btn-secondary"
                                    type="button"
                                    disabled={disabled}
                                    dialog={close => <RegisterDialog server={ selectedServer } close={close} />}
                                >Register</ModalButton>
                            </div>
                        }

                        <div className="separator"/>
                        <div className="col">
                            <ModalButton
                                className="btn btn-secondary"
                                disabled={disabled}
                                dialog={ close => <CreateServerDialog close={close} /> }
                            >Add Server</ModalButton>
                        </div>
                    </div>
                    <Progress />
                </div>
            </header>
            <div className="test-suite">
                <div className="container">
                    <Title>Test Runner</Title>
                    { selectedId ? <TestGroupComponent item={tests.tests[""]} /> : <TestSuiteHome /> }
                </div>
            </div>
        </>
    );
}

