
export default function ErrorView({ error }: { error?: Error | string | null })
{
    if (!error) {
        return null
    }

    return <div className="alert alert-danger">
        { String(error) }
    </div>
}
