import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import AppHeader    from '../Header'
import AppFooter    from "../Footer"
import { Provider } from "react-redux"
import store        from "../../store"
import LoginPage    from "../LoginPage"
import Logout       from "../Logout"
import ReportsPage  from "../ReportsPage"
import Register     from "../Register"
import ServersList  from "../Servers/List"
import TestSuite    from "../TestSuite"
import "./App.scss"
  

export default function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppHeader/>
                <div className="app">
                    <Routes>
                        <Route path="/" element={ <Navigate to="/tests" /> } />
                        <Route path="/servers"          element={ <ServersList />  } />
                        <Route path="/tests"            element={ <TestSuite />    } />
                        <Route path="/tests/server/:id" element={ <TestSuite />    } />
                        <Route path="/reports"          element={ <ReportsPage />  } />
                        <Route path="/register"         element={ <Register />     } />
                        <Route path="/login"            element={ <LoginPage />    } />
                        <Route path="/logout"           element={ <Logout />       } />
                        <Route path="/*" element={
                            <h2 className="text-center"><br/>Not Found</h2>
                        } />
                    </Routes>
                </div>
                <AppFooter/>
            </BrowserRouter>
        </Provider>
    );
}
