import { TestNode } from '..';


export function className(map: Record<string, any>)
{
    return Object.keys(map).filter(
        key => key && key !== "undefined" && !!map[key]
    ).join(" ");
}

export function versionCheck(vA: string, vB: string)
{
    let a = String(vA || "1.0").trim().split(/\s*\.\s*/);
    let b = String(vB || "1.0").trim().split(/\s*\.\s*/);
    while(a.length && b.length) {
        if (+(a.shift())! > +(b.shift())!) {
            return false;
        }
    }
    return true;
}

export function matchesVersion(test: TestNode, version: string)
{
    if (!versionCheck(test.minVersion || "", version)) {
        return false;
    }

    if (test.maxVersion && !versionCheck(version, test.maxVersion || "")) {
        return false;
    }

    return true;
}

export async function request(input: RequestInfo | URL, init?: RequestInit)
{
    const res  = await fetch(input, init)
    const text = await res.text()

    if (!res.ok) {
        throw new Error(res.status + " " + res.statusText + ":\n" + text)
    }

    if (res.headers.get("content-type")?.includes("json")) {
        return JSON.parse(text || "null")
    }

    return text
}

