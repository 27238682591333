import io from "socket.io-client";

const socket = io.connect(process.env.REACT_APP_BACKEND_HOST, {
    // whether to reconnect automatically
    reconnection: true,

    // number of reconnection attempts before giving up
    reconnectionAttempts: Infinity,

    // how long to initially wait before attempting a new reconnection (1000).
    // Affected by +/- randomizationFactor, for example the default initial
    // delay will be between 500 to 1500ms.
    reconnectionDelay: 100,

    // maximum amount of time to wait between re-connections (5000). Each attempt
    // increases the reconnection delay by 2x along with a randomization as above
    reconnectionDelayMax: 5000,

    // 	0 <= randomizationFactor <= 1
    randomizationFactor: 0.5,

    // how many ms before sending a new ping packet
    pingInterval: 5000,

    // how many ms without a pong packet to consider the connection closed
    pingTimeout: 60000,

    // a list of transports to try (in order). Engine always attempts to connect
    // directly with the first one, provided the feature detection test for it passes.
    transports: [
        // 'polling',
        'websocket'
    ],

    // whether the client should try to upgrade the transport from long-polling
    // to something better.
    upgrade: false
});

export default socket;
