import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk                     from "redux-thunk"
import serversReducer, { loadAvailableServers }            from "./servers"
import testsReducer, {loadTests, TestInfo, TEST_ENDED, TEST_STARTED} from "./tests"
import auth                      from "./auth"
// import { initSocket }            from "./tests"
import io                 from "../io"

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

// const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });

const store = createStore(
    combineReducers({
        servers: serversReducer,
        tests  : testsReducer,
        auth
    }),
    composeEnhancers ? composeEnhancers({ trace: true, traceLimit: 25 })(applyMiddleware(thunk)) : applyMiddleware(thunk)
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


// io.removeListener("connect");

io.on("connect", () => {

    io.removeListener("testStart");
    io.removeListener("testEnd"); 
    io.removeListener("disconnect");  

    // console.log("io:connect")     

    io.on("testEnd", (result: TestInfo) => {
        // console.log("io:testEnd", result)
        store.dispatch({ type: TEST_ENDED, payload: result });
    });

    io.on("testStart", (result: TestInfo) => {
        // console.log("io:testStart", result) 
        store.dispatch({ type: TEST_STARTED, payload: result });
    });

    // In case the backend wants to log errors to the browser console
    io.on("custom:error", (error: string) => {
        console.error(error)
    });
  
    io.on("disconnect", () => {
        // console.log("io:disconnect") 
        io.removeListener("testStart");
        io.removeListener("testEnd");
    });
});


// Preload tests
store.dispatch(loadTests() as any); 

// Preload servers
store.dispatch(loadAvailableServers(store.getState().servers.selectedId) as any);


export default store;
