import { NavLink } from "react-router-dom"
import                  "./Header.scss"


export default function AppHeader()
{
    return (
        <header className="app-header">
            <NavLink to="/">
                <h1 className="app-logo">
                    <img src="/logo.png" alt="SMART Logo"/> Bulk Data Tester
                </h1>
            </NavLink>
            <nav className="app-navbar">
                <div className="app-navbar-inner">
                    <NavLink to="/tests">Test Runner</NavLink>
                    <NavLink to="/servers">Servers</NavLink>
                    <NavLink to="/reports">Reports</NavLink>
                </div>
            </nav>
        </header>
    );
}

