import "./TabList.scss"

interface Tab {
    label: string
    id   : string
}

interface TabListProps {
    tabs      : Tab[]
    selectedId: string
    onChange  : (tabId: string) => void
}

export default function TabList(props: TabListProps)
{
    const { tabs, onChange, selectedId } = props;

    const onClick = (tab: Tab) => {
        if (tab.id !== selectedId) {
            onChange(tab.id);
        }
    }
    
    return (
        <div className="nav nav-tabs">
            { tabs.map(t => (<div
                key={ "tab-" + t.id }
                onClick={ () => onClick(t) }
                className={ selectedId === t.id  ? "active" : "" }
                title={ t.label }
            >{ t.label }</div>)) }
        </div>
    );
}
