import { FormEvent, useState } from "react"
import { useDispatch }         from "react-redux"
import ErrorView               from "../Error"
import { Server }              from "../../.."
import { loadAvailableServers, saveServer } from "../../store/servers"
import { request }             from "../../lib"


export default function RegisterDialog({ server, close }: { server: Server, close: () => void })
{
    const dispatch                        = useDispatch<any>()
    const [ name       , setName        ] = useState(server.name  || "");
    const [ email      , setEmail       ] = useState(server.email || "");
    const [ description, setDescription ] = useState("");
    const [ error      , setError       ] = useState(null);

    function submit(e: FormEvent) {
        e.preventDefault();

        const payload = { ...server, name, email, description }

        delete payload.id;

        setError(null)

        request("/api/servers/register", {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-type": "application/json"
            }
        })
        .then(server => {
            dispatch(saveServer(server))
            dispatch(loadAvailableServers())
            close()
        })
        .catch(e => setError(e));
    }

    return (
        <div className="modal">
            <form onSubmit={ submit }>
                <div className="modal-header">
                    Register Your Server
                    <i className="fas fa-times-circle dialog-close-btn" title="Cancel and close" onClick={ close }/>
                </div>
                <div className="modal-body" style={{ maxHeight: "calc(100vh - 140px)", overflow: "auto" }}>
                    <ErrorView error={error}/>
                    <p>
                        This will add your server to our database. The site
                        administrators will be notified and once they approve
                        it, people will be able to test your server online.
                        There are just a few additional bits of information
                        that we need to collect before adding your server to
                        the database.
                    </p>
                    <br/>
                    <div className="server-form">
                        <div className="form-group">
                            <label className="bold">Server Name</label>
                            <div className="form-description">
                                The name of this server as people will see it.
                                This is an opportunity to rename the server
                                before publishing it.
                            </div>
                            <input
                                type="text"
                                required
                                value={ name }
                                onChange={ e => setName(e.target.value) }
                            />
                        </div>
                        <div className="form-group">
                            <label className="bold">Contact Email</label>
                            <div className="form-description">
                                We will only use this to notify you when the server
                                registration is approved. We will NOT display
                                this email anywhere!
                            </div>
                            <input
                                type="email"
                                required
                                value={ email }
                                onChange={ e => setEmail(e.target.value) }
                            />
                        </div>
                        <div className="form-group">
                            <label className="bold">Short Description</label>
                            <div className="form-description">
                                Enter a short description of the server!
                            </div>
                            <textarea
                                rows={ 8 }
                                required
                                value={ description }
                                onChange={ e => setDescription(e.target.value) }
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="modal-footer text-right">
                    <button type="submit" style={{ width: "6em" }} className="btn btn-primary">Register</button>
                    <button type="button" style={{ width: "6em" }} className="btn btn-secondary" onClick={ close }>Cancel</button>
                </div>
            </form>
        </div>
    )
}
