import { Action, Dispatch } from "redux"
import { ThunkDispatch }    from "redux-thunk"
import io                   from "../io"
import { TestNode }         from "../../"
import { RootState }        from "."

export const TEST_RUN          = "tests/testRun"
export const TEST_STARTED      = "tests/testStarted"
export const TEST_ENDED        = "tests/testEnded"
export const SET_AUTO_SCROLL   = "tests/setAutoScroll"
export const SET_LOADING       = "tests/setLoading"
export const SET_TESTS         = "tests/setTests"
export const SET_INITIAL_TESTS = "tests/setInitialTests"
export const SET_RUNNING       = "tests/setRunning"
export const RESET             = "tests/reset"
export const SET_CANCELED      = "tests/setCanceled"
export const SET_BULK_VERSION  = "tests/setBulkDataVersion"

interface TestsState {
    loading        : boolean
    canceled       : boolean
    running        : boolean
    autoScroll     : boolean
    bulkDataVersion: string
    currentPath    : string
    path           : string
    tests          : TestsList
    initialTests   : TestsList
}

export interface TestsListItem extends TestNode {
    pid: string
    type: "group" | "test"
}

export interface TestsList {
    [key: string]: TestsListItem
}

export interface TestInfo {
    decorations: null
    status     : string
    error      : Error | null
    startedAt  : null
    endedAt    : null
    type       : string
    path       : string
    pid        : string
    name       : string
}

interface TestsActionTestRun       { type: typeof TEST_RUN         ; payload: string        }
interface TestsActionTestStarted   { type: typeof TEST_STARTED     ; payload: TestsListItem }
interface TestsActionTestEnded     { type: typeof TEST_ENDED       ; payload: TestsListItem }
interface TestsActionSetAutoScroll { type: typeof SET_AUTO_SCROLL  ; payload: boolean       }
interface ActionSetLoading         { type: typeof SET_LOADING      ; payload: boolean       }
interface ActionSetTests           { type: typeof SET_TESTS        ; payload: TestsList     }
interface ActionInitialSetTests    { type: typeof SET_INITIAL_TESTS; payload: TestsList     }
interface ActionSetRunning         { type: typeof SET_RUNNING      ; payload: boolean       }
interface ActionSetBulkVersion     { type: typeof SET_BULK_VERSION ; payload: string        }
interface ActionSetCanceled        { type: typeof SET_CANCELED     ; payload: boolean       }
interface ActionReset              { type: typeof RESET                                     }

type AnyAction = TestsActionTestRun
               | TestsActionTestStarted
               | TestsActionTestEnded
               | ActionSetLoading
               | ActionSetTests
               | ActionInitialSetTests
               | ActionSetRunning
               | ActionReset
               | ActionSetBulkVersion
               | ActionSetCanceled
               | TestsActionSetAutoScroll

// FIXME: This is a config and not really part of the store. Find a better place
export const TestStatusMap = {
    "failed"         : [ "0", "Test failed"                       , "#c84500" ],
    "succeeded"      : [ "1", "Test completed successfully"       , "#019900" ],
    "not-implemented": [ "2", "Test not implemented"              , "#ea9000" ],
    "skipped"        : [ "3", "Test skipped"                      , "#85b0f0" ],
    "aborted"        : [ "4", "Test aborted"                      , "#f19b46" ],
    "warned"         : [ "5", "Test completed with warnings"      , "#f19b46" ],
    "not-supported"  : [ "6", "Test not supported by this server" , "#AAAAAA" ],
    "running"        : [ "7", "Test in progress"                  , "#666666" ],
    "unknown"        : [ "8", "Unknown status"                    , "#999999" ],
}

const initialState: TestsState = {
    loading        : false,
    canceled       : false,
    running        : false,
    autoScroll     : false,
    bulkDataVersion: "2.0",
    path           : "",
    currentPath    : "",
    tests          : {},
    initialTests   : {}
};


export const runNode = (path: string, serverId: number) => {
    return async function (dispatch: ThunkDispatch<RootState, void, Action>, getState: () => RootState) {
        
        const state = getState();

        const options: any = {
            id: path,
            targetServerId: serverId + "",
            apiVersion: state.tests.bulkDataVersion,
            settings: {}
        };
        
        const server = state.servers.items.find(o => o.id + "" === state.servers.selectedId + "");

        if (server) {
            
            // We need to do some modifications for the local servers!
            if (server.isLocal) {
                options.settings = {
                    ...options.settings,
                    ...server.settings
                };

                // if (options.settings.jwks && Array.isArray(options.settings.jwks.keys)) {
                //     const keys = findKeyPair(options.settings.jwks.keys);
                //     options.settings.privateKey = keys?.privateKey || null;
                //     options.settings.publicKey = keys?.publicKey || null;
                // }

                // There is no record on the database for this server so don't
                // attempt to find one (it is local only)
                delete options.targetServerId;
                // console.log(options, server)
            }

            dispatch({ type: TEST_RUN, payload: path });
            io.emit("run", options);
        }

        // return dispatch({ type: "ERROR", payload: "No Server" });
    }
}

export function cancel(all?: boolean) {
    return function (dispatch: Dispatch) {
        if (all) {
            // io.once("canceled", () => {
                dispatch({ type: SET_CANCELED, payload: true  })
                dispatch({ type: SET_RUNNING , payload: false });
            // })
        }
        io.emit("cancel", all);
    }
}

export function setBulkDataVersion(version: string) {
    return ({ type: SET_BULK_VERSION, payload: version });
}

export function loadTests() {
    return function (dispatch: Dispatch) {
        dispatch({ type: SET_LOADING, payload: true });
        fetch("/api/list")
        .then(res => res.json())
        .then(json => {
            const data: Record<string, TestsListItem> = {};
            
            function walk(node: TestNode, pid = "") {
                if (node.children) {
                    data[node.path + ""] = {
                        ...node,
                        type: "group",
                        pid
                    };

                    node.children.forEach(child => walk(child, node.path + ""));
                } else {
                    data[node.path + ""] = {
                        ...node,
                        type: "test",
                        pid
                    };
                }

                return data;
            }

            const tests = walk(json);

            dispatch({ type: SET_INITIAL_TESTS, payload: tests });
            dispatch({ type: SET_TESTS        , payload: tests });
        })
        .finally(() => dispatch({ type: SET_LOADING, payload: false }));
    }
}

export function reset() {
    return ({ type: RESET })
}

export function getTests(tests: TestsList, path = "") {
    if (!path) {
         return Object.values(tests).filter(node => node.type === "test")
    }
    // console.log(tests)
    const node = tests[path]
    if (!node) {
        return []
    }
    if (node.type === "test") {
        return [node]
    }
    
    return Object.values(tests)
        .filter(node => node.type === "test" && node.path.startsWith(path + "."))
}

export function setAutoScroll(on: boolean) {
    return { type: SET_AUTO_SCROLL, payload: on }
}

export default function testsReducer(state = initialState, action: AnyAction): TestsState
{
    switch (action.type) {

        case SET_LOADING:
            return { ...state, loading: action.payload };
        case SET_TESTS:
            return { ...state, tests: action.payload };
        case SET_INITIAL_TESTS:
            return { ...state, initialTests: JSON.parse(JSON.stringify(action.payload)) };
        case SET_RUNNING:
            return { ...state, running: action.payload };
        case SET_BULK_VERSION:
            return { ...state, bulkDataVersion: action.payload };
        case SET_CANCELED:
            return { ...state, canceled: action.payload }
        case SET_AUTO_SCROLL:
            return { ...state, autoScroll: action.payload }

        case RESET:
            return {
                ...state,
                loading : false,
                canceled: false,
                currentPath: "",
                path: "",
                tests: JSON.parse(JSON.stringify(state.initialTests))
            };

        // When the manually start a test or a group
        case TEST_RUN: {
            
            const nextState: Partial<TestsState> = {
                ...JSON.parse(JSON.stringify(state)),
                path    : action.payload,
                currentPath: action.payload,
                running : true,
                canceled: false
            };

            const children = getTests(nextState.tests!, action.payload);

            children.forEach(node => {
                node.console   = []
                node.status    = ""
                node.error     = undefined
                node.startedAt = undefined
                node.endedAt   = undefined
            })

            return nextState as TestsState;
        }

        // When the back-end reports a single test node as started
        case TEST_STARTED: {
            const nextState = { ...state, currentPath: action.payload.path }
            nextState.tests[action.payload.path] = {
                ...nextState.tests[action.payload.path],
                ...action.payload
            };
            return nextState;
        }

        // When the back-end reports a single test node as ended
        case TEST_ENDED: {
            const nextState = { ...state, currentPath: "" };
            nextState.tests[action.payload.path] = {
                ...nextState.tests[action.payload.path],
                console: [],
                ...action.payload
            };

            const scheduled = getTests(nextState.tests, nextState.path);
            const total = scheduled.length;
            const completed = scheduled.filter(t => t.endedAt).length;
            nextState.running = !nextState.canceled && completed < total
            return nextState;
        }

        default:
            return state;
    }
}

