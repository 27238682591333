import { Dispatch }             from "redux"
import { RootState }            from "."
import { Server, StoredServer } from "../.."

interface ServersState {
    selectedId: number | null
    loading   : boolean
    error     : Error | null
    items     : Server[]
}

const SELECT      = "servers/select"
const SET_LOADING = "servers/setLoading"
const SET_ERROR   = "servers/setError"
const LOAD        = "servers/load"
const SAVE        = "servers/save"
const DELETE      = "servers/delete"


interface ActionSelect     { type: typeof SELECT     , payload: number | null }
interface ActionSetLoading { type: typeof SET_LOADING, payload: boolean       }
interface ActionSetError   { type: typeof SET_ERROR  , payload: Error | null  }
interface ActionLoad       { type: typeof LOAD       , payload: Server[]      }
interface ActionSave       { type: typeof SAVE       , payload: Server        }
interface ActionDelete     { type: typeof DELETE     , payload?: number       }

type AnyServersAction = ActionSelect
                      | ActionSetLoading
                      | ActionSetError
                      | ActionLoad
                      | ActionSave
                      | ActionDelete

const initialState: ServersState = {
    selectedId: null,
    loading   : false,
    error     : null,
    items     : JSON.parse(localStorage.servers || "[]")
};


export function setSelectedServerId(id: number | null): ActionSelect {
    return { type: SELECT, payload: id };
}

export function setLoading(payload: boolean): ActionSetLoading {
    return { type: SET_LOADING, payload };
}

export function setError(payload: Error | null): ActionSetError {
    return { type: SET_ERROR, payload };
}

export function setAvailableServers(payload: Server[]): ActionLoad {
    return { type: LOAD, payload };
}

export function saveServer(payload: Server): ActionSave {
    return { type: SAVE, payload };
}

export function deleteServer(id?: number): ActionDelete {
    return { type: DELETE, payload: id };
}

export function loadAvailableServers(idToSelect?: number | null) {
    return function (dispatch: Dispatch, getState: () => RootState) {
        dispatch(setLoading(true))
        dispatch(setError(null))
        return fetch("/api/servers")
        .then(res => res.json())
        .then((servers: StoredServer[]) => {

            // If idToSelect is passed and found in servers
            if (idToSelect) {
                if (servers.find(server => server.id === idToSelect)) {
                    dispatch(setSelectedServerId(idToSelect))
                }
            }

            // // No id - select the first server
            // else {
            //     idToSelect = getState().servers.selectedId
            //     // if we have any servers
            //     if (servers.length) {
            //         dispatch(setSelectedServerId(idToSelect || +servers[0].id))
            //     }
            // }

            dispatch(setAvailableServers([
                ...servers,
                ...JSON.parse(localStorage.servers || "[]")
            ]));
            dispatch(setLoading(false))
        })
        .catch(error => {
            dispatch(setLoading(false))
            dispatch(setError(error))
        });
    };
}

function saveLocalServers(state: Partial<ServersState>) {
    localStorage.servers = JSON.stringify(
        (state.items || []).filter(s => s.isLocal)
    );
}

export default function serversReducer(state = initialState, action: AnyServersAction)
{
    switch (action.type) {

        case SELECT:
            return { ...state, selectedId: action.payload };
        
        case SET_LOADING:
            return { ...state, loading: action.payload };

        case SET_ERROR:
            return { ...state, error: action.payload };
        
        case LOAD:
            return { ...state, loading: false, items: [ ...action.payload ] };

        case SAVE: {
            const nextState = { ...state }
            if (action.payload.id) {
                const index = nextState.items.findIndex(s => s.id + "" === action.payload.id + "")
                nextState.items[index] = action.payload
                if (action.payload.isLocal) saveLocalServers(nextState);
            } else {
                const id = Date.now()
                nextState.items.push({ ...action.payload, isLocal: true, id });
                nextState.selectedId = id
                saveLocalServers(nextState);
            }
            return nextState;
        }

        case DELETE: {
            const nextState = { ...state }
            const id = action.payload || nextState.selectedId
            if (id) {
                const index = nextState.items.findIndex(s => s.id + "" === id + "")
                nextState.items.splice(index, 1)
                nextState.selectedId = nextState.items[0]?.id || null
                saveLocalServers(nextState);
            }
            return nextState
        }

        default:
            return state;
    }
}

