import { useEffect, useState }    from "react"
import { Link }                   from "react-router-dom"
import moment                     from "moment"
import MarkdownIt                 from "markdown-it"
import Title                      from "../Title"
import { StoredServer, TestNode } from "../../../index"
import Tooltip                    from "../Tooltip"
import "./ReportsPage.scss"

const md = MarkdownIt({ html: true });

type TestStatus = "succeeded" | "failed" | "not-implemented" | "not-supported" | "warned"

interface DataEntry {
    name: string
    status: string
    testId: string
    serverId: number
    description: string
    warnings: string
    error: string
    endedAt: Date
}

function getStatusMessage(status: TestStatus)
{
    if (status === "succeeded")
        return "Test passed";
    if (status === "failed")
        return "Test failed";
    if (status === "not-implemented")
        return "Test not implemented";
    if (status === "not-supported")
        return "Not supported by this server";
    if (status === "warned")
        return "Has warnings";
    return status;
}

function TestIcon({ status = "" })
{
    if (status === "succeeded")
        return <i className="fas fa-check-circle" style={{ color: "rgb(0, 153, 0)"}} />
    if (status === "failed")
        return <i className="fas fa-times-circle" style={{ color: "rgb(153, 0, 0)"}} />
    if (status === "not-implemented")
        return <i className="fas fa-minus-circle" style={{ color: "#963" }} />
    if (status === "not-supported")
        return <i className="fas fa-minus-circle" style={{ color: "#999" }} />
    if (status === "warned")
        return <i className="fas fa-exclamation-triangle" style={{ color: "#e66f00" }} />
    return <span>{ status || "-" }</span>;
}

function TestResultCell({
    test,
    server,
    data
}: {
    test: TestNode,
    server: { name: string, id: string }
    data: any[]
})
{
    const result = data.find(item => item.testId === test.id && item.serverId + "" === server.id + "") || {};
    const tooltipHTML = [];


    if (result.status) {
        tooltipHTML.push(`<h3 class="text-center">${getStatusMessage(result.status)}</h3>`);
    }

    if (result.description) {
        tooltipHTML.push(md.render(result.description).replace(/<a\s+href="([^"]*)">/g, '<a target="_blank" href="$1">'));
    }

    if (result.error) {
        tooltipHTML.push(`<pre class="color-danger">${result.error}</pre>`)
    }

    if (result.warnings) {
        tooltipHTML.push(`<div class="color-warning">${result.warnings}</div>`);
    }
    if (tooltipHTML.length) {
        tooltipHTML.push(`<footer><b>Executed at</b> ${moment(result.endedAt).format("MM/DD/YYYY h:mm a")}</footer>`);
    }

    return (
        <td className={result.status || null}>
            <TestIcon status={result?.status} />
            { tooltipHTML.length ?
                <Tooltip html={ tooltipHTML.join('<hr class="light"/>') }/> :
                null
            }
        </td>
    );
}

function TestResults({ testNode, servers, data, level = 0, hidden = false }: {
    level?: number
    hidden?: boolean
    servers: { name: string, id: string }[]
    testNode: TestNode
    data: any[]
})
{
    const [ collapsed, setCollapsed ] = useState(false);

    // For leaf nodes render single row and exit
    if (!testNode.children) {
        return (
            <tr>
                <th style={{ paddingLeft: level * 18 }}>{ testNode.name }</th>
                { servers.map(server => (
                    <TestResultCell
                        key={"result-" + server.id + "-" + testNode.path}
                        test={ testNode }
                        server={ server }
                        data={ data }
                    />
                )) }
            </tr>
        );
    }

    // Render a row that corresponds to this test node

    // If this is a group, then also render its children as rows

    const serversLength = servers.length;

    let elements = [];

    if (testNode.path) {
        elements.push(
            <tr
                key={ "row-" + testNode.path }
                onClick={() => setCollapsed(!collapsed)}
                className={[
                    "group",
                    collapsed ? "collapsed" : "",
                    hidden    ? "hidden"    : ""
                ].filter(Boolean).join(" ")}
            >
                <th style={{ paddingLeft: level * 18 }}>
                    { testNode.name }
                </th>
                <td colSpan={ serversLength } />
            </tr>
        );
    }

    if (!collapsed && testNode.children) {
        elements = elements.concat(testNode.children.map(child => (
            <TestResults
                key={ "row-" + child.path }
                data={ data }
                testNode={child}
                servers={servers}
                level={level + 1}
            />
        )));
    }

    return <>{ elements }</>;
}

export default function ReportsPage()
{
    const [ loading, setLoading ] = useState(true)
    const [ error  , setError   ] = useState(null)
    const [ servers, setServers ] = useState<{ id: string, name: string }[]>([])
    const [ data   , setData    ] = useState<DataEntry[]>([])
    const [ tests  , setTests   ] = useState<TestNode | null>(null)

    useEffect(() => {
        if (loading) {
            fetch("/api/reports/grid")
            .then(res => {
                if (!res.ok) {
                    throw new Error(`${res.status}: ${res.statusText}`);
                }
                return res;
            })
            .then(res => res.json())
            .then(({ servers, data, tests }) => {
                setServers(servers.sort((a: StoredServer, b: StoredServer) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
                setData(data)
                setTests(tests)
                setError(null)
                setLoading(false)
            })
            .catch(error => {
                setError(error)
                setLoading(false)
            });
        }
    }, [loading])

    if (loading || !tests) {
        return <b>Loading...</b>
    }

    if (error) {
        return <b>{ String(error) }</b>
    }

    return (
        <div className="container">
            <h3>Support Grid</h3>
            <Title>Reports - Support Grid</Title>
            <br/>
            <table className="server-table">
                <thead>
                    <tr>
                        <th></th>
                        { servers.map(server => (
                            <th key={server.id}>
                                <Link to={`/tests/server/${server.id}`}>{server.name}</Link>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <TestResults testNode={ tests } servers={ servers } data={ data }/>
                </tbody>
            </table>
        </div>
    );
}
