import { Link }               from "react-router-dom"
import { useSelector }        from "react-redux"
import Title                  from "../Title"
import { RootState }          from "../../store"
import { StoredServer }       from "../../.."
import { ModalButton }        from "../Modal"
import DeleteServerDialog     from "../Dialogs/DeleteServer"
import { CreateServerDialog } from "../Dialogs/CreateServer"
import { UpdateServerDialog } from "../Dialogs/UpdateServer"
import                               "../../styles/grid.scss"
import                               "./ServersPage.scss"


function Server({
    server,
    editable
}: {
    server: StoredServer
    editable: boolean
}) {
    const { settings } = server;

    return (
        <div className="server-info-entry">
            <h3>{ server.name }</h3>
            { server.description && <p>{ server.description }</p> }
            <table>
                <tbody>
                    <tr>
                        <th>BaseURL:</th>
                        <td>
                            {
                                settings.baseURL ?
                                <a target="_blank" rel="noopener noreferrer" href={settings.baseURL}>{settings.baseURL}</a> :
                                "Not specified"
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>Token Endpoint:</th>
                        <td>
                            { settings.authentication?.tokenEndpoint || "Not specified" }
                        </td>
                    </tr>
                    <tr>
                        <th>Secure:</th>
                        <td>
                        {
                            settings.requests?.strictSSL ?
                                "Yes" :
                                settings.baseURL && settings.baseURL.indexOf("https://") === 0 ?
                                    "Self Signed" :
                                    "No"
                        }
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="server-info-footer">
                { editable && <div className="float-right admin-buttons">
                    <ModalButton
                        className="btn btn-warning btn-sm"
                        dialog={ close => <UpdateServerDialog server={server} close={close} />}>
                        Edit
                    </ModalButton>
                    <ModalButton
                        className="btn btn-danger btn-sm"
                        dialog={ close => <DeleteServerDialog server={server} close={close} /> }>
                        Delete
                    </ModalButton>
                </div> }
                <Link to={`/tests/server/${server.id}`} className="btn btn-secondary btn-sm">Test</Link>
            </div>
        </div>
    )
}

export default function ServersPage()
{
    const { loading, error, items } = useSelector((state: RootState) => state.servers);
    const { currentUser } = useSelector((state: RootState) => state.auth);

    if (error) {
        return (
            <div>{ String(error) }</div>
        );
    }

    if (loading) {
        return (
            <div>"Loading...</div>
        );
    }

    const onlinePublic = items.filter(s => !s.isLocal && s.public)
    const onlinePrivate = items.filter(s => !s.isLocal && !s.public)
    const local = items.filter(s => s.isLocal)
    let columns = 1
    if (local.length) {
        columns += 1
    }
    if (onlinePrivate.length && currentUser) {
        columns += 1
    }
    
    return (
        <div className="container">
            <Title>List Servers</Title>
            <div className="text-right" style={{ position: "sticky", background: "#FFF", top: 0, padding: "1ex 0", zIndex: 100, boxShadow: "0 7px 10px -12px", margin: "0 0 1em" }}>
                <ModalButton
                    className="btn btn-success"
                    dialog={ close => <CreateServerDialog close={close} /> }>
                    <i className="fas fa-plus-circle"/> Add Server
                </ModalButton>
            </div>
            
            <div className={"grid-row c" + columns}>
                <div className="grid-col public">
                    <h2>Registered Servers</h2>
                    <p className="color-muted">Everybody can see and test these servers</p>
                    <hr/>
                    <br/>
                    { onlinePublic.map(
                        s => <Server server={s as StoredServer} key={s.id} editable={!!currentUser}/>
                    ) }
                </div>
                {
                    currentUser && (
                        <div className="grid-col private">
                            <h2>Private Servers</h2>
                            <p className="color-muted">Only site administrators can see and test these servers</p>
                            <hr/>
                            <br/>
                            { onlinePrivate.map(
                                s => <Server server={s as StoredServer} key={s.id} editable={!!currentUser}/>
                            ) }
                        </div>
                    )
                }
                { local.length > 0 && (
                    <div className="grid-col local">
                        <h2>My Local Servers</h2>
                        <p className="color-muted">Only I can see and test these servers in this browser</p>
                        <hr/>
                        <br/>
                        { items.filter(s => s.isLocal).map(
                            s => <Server server={s as StoredServer} key={s.id} editable />
                        ) }
                    </div>
                ) }
            </div>
        </div>
    );
}

