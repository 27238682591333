import { FormEvent, useEffect, useState } from "react"
import { useSelector, useDispatch }       from "react-redux"
import { useNavigate }                    from "react-router"
import { Link }                           from "react-router-dom";
import { RootState }                      from "../../store";
import { login }                          from "../../store/auth";
import Title                              from "../Title"


export default function LoginForm()
{
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    const { loading, error, currentUser } = useSelector((state: RootState) => state.auth)

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        dispatch(login(username, password));
    };

    useEffect(() => {
        if (currentUser) {
            return navigate(-1)
        }
    }, [currentUser, navigate])

    return (
        <div style={{ maxWidth: 360, margin: "auto" }}>
            <Title>Login</Title>
            <form onSubmit={ onSubmit }>
                <br/>
                <br/>
                <div className="modal">
                    <div className="modal-header">Admin Login</div>
                    <div className="modal-body" style={{ width: 350 }}>
                        <br />
                        { error && (
                            <div className="alert alert-danger">
                                { String(error) }
                            </div>
                        )}
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Enter username"
                                name="username"
                                value={ username }
                                onChange={ e => setUsername(e.target.value) }
                                disabled={ loading }
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                name="password"
                                placeholder="Enter password"
                                value={ password }
                                onChange={ e => setPassword(e.target.value) }
                                disabled={ loading }
                            />
                        </div>
                        <br />
                    </div>
                    <div className="modal-footer text-right">
                        <Link
                            className="float-left btn btn-secondary text-center disabled"
                            style={{ width: "7em" }}
                            to="/register"
                        >Register</Link>
                        <button
                            className="btn btn-primary"
                            disabled={ loading }
                            type="submit"
                            style={{ width: "7em" }}
                        >
                            { loading ?
                                <i className="fas fa-spinner fa-spin"/> :
                                <i className="fas fa-sign-in-alt" />
                            } Login
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

