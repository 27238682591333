import { FormEvent, useState } from "react"
import Title from "../Title"

export default function Register()
{
    const [username , setUsername ] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [loading  , setLoading  ] = useState(false)
    const [error    , setError    ] = useState(null)
    const [registeredUsername, setRegisteredUsername] = useState("")

    const onSubmit = (e: FormEvent) =>
    {
        e.preventDefault()
        setLoading(true)    
        fetch("/auth/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: `username=${encodeURIComponent(username)}&` +
                `password1=${encodeURIComponent(password1)}&` +
                `password2=${encodeURIComponent(password2)}`
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                throw new Error(json.error);
            }
            if (!json.username) {
                throw new Error("Registration failed!");
            }
            return json;
        })
        .then(json => {
            setLoading(false)
            setError(null)
            setRegisteredUsername(json.username)
        })
        .catch(error => {
            setLoading(false)
            setError(error)
        })
    }

    if (registeredUsername) {
        return (
            <div className="alert alert-success" style={{ maxWidth: 480, margin: "auto" }}>
                <Title>Register</Title>
                Your registration was successful!<br/>
                You can now log-in as <b>{registeredUsername}</b>.
            </div>
        );    
    }

    return (
        <form style={{ maxWidth: 480, margin: "auto" }} onSubmit={ onSubmit }>
            <Title>Register</Title>
            <h2>Register { loading && <small> <i className="fas fa-spinner fa-spin" style={{ verticalAlign: "top"}}/></small> }</h2>
            <p className="small text-muted">Once you register, you will have the ability to add and edit bulk-data servers</p>
            <hr/>
            <br/>
            { error && (
                <div className="alert alert-danger">
                    { String(error) }
                </div>
            )}
            <div className="form-group">
                <label htmlFor="username" className="bold">Username</label>
                <input
                    type="text"
                    name="username"
                    id="username"
                    value={ username }
                    onChange={ e => setUsername(e.target.value) }
                    disabled={ loading }
                />
            </div>
            <div className="form-group">
                <label htmlFor="password1" className="bold">Password</label>
                <input
                    type="password"
                    name="password1"
                    id="password1"
                    value={ password1 }
                    onChange={ e => setPassword1(e.target.value) }
                    disabled={ loading }
                />
            </div>
            <div className="form-group">
                <label htmlFor="password2" className="bold">Repeat Password</label>
                <input
                    type="password"
                    name="password2"
                    id="password2"
                    value={ password2 }
                    onChange={ e => setPassword2(e.target.value) }
                    disabled={ loading }
                />
            </div>
            <br/>
            <hr/>
            <div className="form-group text-center">
                <button type="submit" className="btn btn-primary">Register</button>
            </div>
        </form>
    )
}
