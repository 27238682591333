import { useState }     from "react"
import { useDispatch }  from "react-redux"
import { useNavigate } from "react-router"
import { Server }       from "../../.."
import { deleteServer, loadAvailableServers, setSelectedServerId } from "../../store/servers"
import ErrorView        from "../Error"
import Loader           from "../Loader"


export default function DeleteServerDialog({ server, close }: { server: Server, close: () => void })
{
    const [ state, setState ] = useState({ loading: false, error: null })

    const dispatch = useDispatch<any>()

    const navigate = useNavigate()

    const { loading, error } = state

    const onSubmit = async () => {
        if (!server.isLocal) {
            setState({ ...state, loading: true })
            
            await fetch(`/api/servers/${server.id}`, { method: "DELETE" })
                .then(res => res.json())
                .then(result => {
                    if (!result) {
                        throw new Error("Failed to delete the server")
                    }
                    setState({ loading: false, error: null })
                })
                .catch(error => {
                    setState({ loading: false, error })
                });
        }

        dispatch(deleteServer(server.id))
        dispatch(loadAvailableServers())
        dispatch(setSelectedServerId(null))
        navigate("/tests")
        // close()
    };

    return (
        <div className="modal">
            <div className="modal-header color-danger">
                Please Confirm!
                <i className="fas fa-times-circle dialog-close-btn" title="Cancel and close" onClick={ close }/>
            </div>
            <div className="modal-body">
                { loading && <Loader /> }
                <ErrorView error={error} />
                <h3>{ server.name }</h3>
                <table>
                    <tbody>
                        <tr>
                            <th className="text-right">Base URL:</th>
                            <td>{ server.settings?.baseURL || "Not specified" }</td>
                        </tr>
                        <tr>
                            <th className="text-right">Token Endpoint:</th>
                            <td>{ server.settings?.authentication?.tokenEndpoint || "Not specified" }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="modal-footer text-center">
                <button type="button" className="btn btn-danger" onClick={ onSubmit }>Delete Server</button>
                <button type="button" className="btn btn-secondary" onClick={ close } autoFocus>Cancel</button>
            </div>
        </div>
    )
}
