import { useCallback, useState } from "react"
import TabList from "../TabList"
import { MonacoEditor } from "../MonacoEditor"
import { Server, NormalizedConfig, AuthenticationOptions, ServerConfig } from "../../.."
import "./Form.scss"
import ErrorView from "../Error"

interface FormProps {
    server: Server
    local: boolean
    onChange: (state: Server) => void
    onSubmit: () => Promise<any>
    close: () => void
}

export default function Form({ server, onChange, onSubmit, close }: FormProps)
{
    const [tab, setTab] = useState("general")
    const [normalizeCount, setNormalizeCount] = useState(0)
    const [normalizeError, setNormalizeError] = useState<string>("")
    const [loading       , setLoading       ] = useState(false)
    const [saveError     , setSaveError     ] = useState<string>("")
    const [normalizing   , setNormalizing   ] = useState(false)

    const normalize = () => {
        if (loading) return;
        setLoading(true)
        setNormalizing(true)
        return fetch("/api/bdt/normalize", {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(server.settings)
        })
        .then(res => {
            if (!res.ok) {
                return res.text().then(error => {
                    throw error || res.statusText
                })
            }
            return res.json()
        })
        .then(
            result => {
                onChange({
                    ...server,
                    settings: {
                        ...server.settings,
                        ...result
                    }
                });

                setNormalizeError("")
                setNormalizeCount(normalizeCount + 1);
            }
        )
        .catch(error => {
            setNormalizeError("normalize: " + error + " (fix your settings and click normalize again)")
        })
        .finally(() => {
            setLoading(false)
            setNormalizing(false)
        })
    }

    // Used to render the state in the Monaco editor
    const serialize = useCallback(() => {
        const dataObj = { ...server };

        // Remove hidden (server-side) props
        [
            "id",
            "createdAt",
            "updatedAt",
            "email",
            "isLocal",
            "editable"
        ].forEach(prop => {
            if (prop in dataObj) {
                // @ts-ignore
                delete dataObj[prop];
            }
        });

        return JSON.stringify(dataObj, null, 4);
    }, [server])

    const validate = () => {
        const {
            settings: {
                baseURL = "",
                authentication: {
                    type          = "none",
                    tokenEndpoint = "",
                    clientId      = "",
                    clientSecret  = ""
                } = {}
            } = {},
            name = ""
        } = server;

        const errors = [];

        if (!baseURL || !String(baseURL).match(/^https?:\/\/.+/)) {
            errors.push("Server baseUrl is required and must be valid URL");
        }

        if (!name) {
            errors.push("Server name is required");
        }

        if (type === "backend-services") {
            if (!tokenEndpoint) {
                errors.push("A token endpoint is required for backend-services authentication");
            }
            if (!clientId) {
                errors.push("A clientId is required for backend-services authentication");
            }
            // if (settings?.authentication.jwksAuth && !settings?.authentication.jwks) {
            //     errors.push("A JWKS key set is required");
            // }
        }

        if (type === "client-credentials") {
            if (!tokenEndpoint) {
                errors.push("A token endpoint is required for client-credentials authentication");
            }
            if (!clientId) {
                errors.push("A clientId is required for client-credentials authentication");
            }
            if (!clientSecret) {
                errors.push("A clientSecret is required for client-credentials authentication");
            }
        }

        if (normalizeError) {
            errors.push(normalizeError)
        }

        return errors;
    };

    const errors = validate()

    return (
        <div className="modal">
            <div className="modal-header">
                { server.id ? "Update" : "Create" } Bulk Data Server
                <i className="fas fa-times-circle dialog-close-btn" title="Cancel and close" onClick={ close }/>
            </div>
            <div className="modal-body server-editor" style={{ width: 1200, height: 675, maxHeight: "calc(100vh - 140px)", overflow: "auto" }}>
                <form id="server-form" className="server-form" onSubmit={e => {
                    e.preventDefault();
                    setSaveError("")
                    normalize()?.then(() => {
                        if (!normalizeError) {
                            onSubmit().then(close, e => setSaveError(e + ""))
                        }
                    })
                }}>
                    <TabList
                        tabs={[
                            { id: "general", label: "General"        },
                            { id: "bulk"   , label: "Bulk Data"      },
                            { id: "auth"   , label: "Authentication" },
                            { id: "json"   , label: "Edit as JSON"   }
                        ]}
                        selectedId={ tab }
                        onChange={ setTab }
                    />
                    { !server.id && <div className="alert alert-info">
                        The server will be stored in your browser's local storage.
                        Nobody else will be able to test this server, but you will have
                        the chance to publish it and make it available for everyone to
                        test.
                    </div> }
                    { server.id && server.isLocal && <div className="alert alert-info">
                        This server is stored in your browser's local storage. Nobody
                        else will be able to test this server, but you will have the
                        chance to publish it and make it available for everyone to test.
                    </div> }
                    {
                        errors.length ? <div id="server-form-error">
                            {
                                errors.map((e, i) => {
                                    return (
                                        <div key={`error-${i + 1}`}>➤ {e}</div>
                                    );
                                })
                            }
                        </div> : null
                    }

                    <ErrorView error={saveError} />

                    {/* General ------------------------------------------------ */}
                    <fieldset disabled={loading} className="grid-row c1" style={{ display: tab === "general"  ? "grid" : "none" }}>
                        <GeneralEditor server={server} onChange={s => onChange({...server, ...s})} />
                        <br/>
                    </fieldset>

                    {/* Bulk Data ---------------------------------------------- */}
                    <fieldset disabled={loading} className="grid-row c2" style={{ display: tab === "bulk"  ? "grid" : "none" }}>
                        <BulkDataEditor server={server} onChange={s => onChange({
                            ...server,
                            settings: {
                                ...server.settings,
                                ...s
                            }
                        })}/>
                    </fieldset>

                    {/* Authentication ----------------------------------------- */}
                    <fieldset disabled={loading} className="grid-row c2" style={{ display: tab === "auth" ? "grid" : "none" }}>
                        <AuthenticationEditor server={server} onChange={settings => {
                            onChange({
                                ...server,
                                settings: {
                                    ...server.settings,
                                    ...settings
                                }
                            })
                        }} key={ tab === "auth" ? "grid" : "none" } />
                        
                    </fieldset>

                    {/* Edit as JSON ------------------------------------------- */}
                    <fieldset disabled={loading} className="grid-row" style={{ display: tab === "json" ? "grid" : "none", flex: "1 1 0px" }}>
                        { tab === "json" && <MonacoEditor key={normalizeCount} value={serialize()} onChange={json => onChange({
                            ...server,
                            ...json as Server
                        })} /> }
                    </fieldset>
                    
                </form>
            </div>
            <div className="modal-footer text-right">
                <button
                    type="button"
                    className="btn btn-secondary"
                    form="server-form"
                    id="server-form-normalize"
                    name="normalizeAction"
                    value="normalize"
                    title="Fetch the CapabilityStatement and try to auto-detect some of the settings"
                    onClick={normalize}
                    style={{
                        float: "left",
                        maxWidth: "45%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                    }}
                >{ normalizing ? <i className="fas fa-circle-notch fa-spin"/> : <i className="fas fa-magic"/> } Normalize</button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    form="server-form"
                    id="server-form-submit"
                    name="submitAction"
                    value="save"
                ><i className="fas fa-save"/> Save</button>
                <button type="button" className="btn btn-secondary" onClick={ close }>Cancel</button>
            </div>
        </div>
    )
}

function BulkDataEditor({
    server,
    onChange
}: {
    server: Server
    onChange: (props: ServerConfig) => void
}) {
    const {
        settings: {
            baseURL,
            fastestResource,
            groupExportEndpoint,
            systemExportEndpoint,
            patientExportEndpoint,
            authentication: {
                scope = "",
                type  = "none"
            } = {}
        } = {}
    } = server

    return (
        <>
            <div className="grid-col">
                <div className="form-group">
                    <label className="bold">Base URL</label>
                    <input
                        type="url"
                        placeholder="Enter the server baseURL"
                        name="baseURL"
                        required
                        value={ baseURL || "" }
                        onChange={e => onChange({ baseURL: e.target.value })}
                    />
                    <p className="form-description">
                        Please enter the base URL of the FHIR server
                    </p>
                </div>
            </div>
            <div className="grid-col">
                <div className="form-group">
                    <label className="bold" htmlFor="fastestResource">
                        Fastest Resource
                    </label>
                    <input
                        type="text"
                        id="fastestResource"
                        disabled={ !baseURL }
                        value={ fastestResource || "" }
                        onChange={ e => onChange({ fastestResource: e.target.value }) }
                    />
                    <p className="form-description">
                        While testing we need to attempt downloading at least one resource type.
                        Please enter the resource type that would be fast to export (because
                        there are not many records of that type). We use <code>Patient</code> by
                        default, just because we presume that it is present on every server.
                    </p>
                </div>
            </div>
            <div className="grid-col">
                <div className="form-group">
                    <label className="bold">Scope</label>
                    <input
                        type="text"
                        value={ scope || "" }
                        disabled={ !baseURL || type !== "backend-services" }
                        placeholder="system/*.read"
                        onChange={ e => onChange({
                            ...server.settings,
                            authentication: {
                                ...server.settings?.authentication,
                                scope: e.target.value
                            }
                        }) }
                    />
                    <p className="form-description">
                        Space-separated list of one or more scopes to request from the server.
                        Typically <code>system/*.read</code> is fine in most cases but if your
                        server needs other scopes you can customize that here. This is only
                        available for servers that use SMART Backend Services authentication.
                    </p>
                </div>
            </div>
            <div className="grid-col">
                <div className="form-group">
                    <label className="bold" htmlFor="groupId">
                        Group Export Endpoint
                    </label>
                    <input
                        type="text"
                        id="groupId"
                        value={ groupExportEndpoint || "" }
                        disabled={ !baseURL }
                        onChange={ e => onChange({ groupExportEndpoint: e.target.value }) }
                    />
                    <p className="form-description">
                        If your server supports group-level export, enter the endpoint of the group you would like to test.
                        Please use the id of the group having the smallest amount of resources. This will not be used if
                        the server does not declare that it supports group-level export in it's CapabilityStatement. The
                        format is <code>/Group/{ "{group-id}" }/$export</code>, where the leading slash is optional.
                    </p>
                </div>
            </div>
            <div className="grid-col">
                <div className="form-group checklist">
                    <label className="checklist-item">
                        <input
                            type="checkbox"
                            checked={ !!systemExportEndpoint }
                            onChange={ e => onChange({ systemExportEndpoint: e.target.checked ? "$export" : "" }) }
                        /><div className="d-inline-block v-top">
                            <b>System-level export</b>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Check this if the server supports system-level export at <code>/$export</code>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <div className="grid-col">
                <div className="form-group checklist">
                    <label className="checklist-item">
                        <input
                            type="checkbox"
                            checked={ !!patientExportEndpoint }
                            onChange={ e => onChange({ patientExportEndpoint: e.target.checked ? "Patient/$export" : "" }) }
                        /><div className="d-inline-block v-top">
                            <b>Patient-level export</b>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Check this if the server supports patient-level export at <code>/Patient/$export</code>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </>
    )
}

function GeneralEditor({
    server,
    onChange
}: {
    server: Server
    onChange: (props: Server) => void
}) {
    return (
        <div className="grid-col">
            <div className="form-group">
                <label className="bold">Server Name</label>
                <input
                    type="text"
                    placeholder="Enter server name"
                    name="name"
                    required
                    value={ server.name || "" }
                    onChange={e => onChange({ name: e.target.value })}
                />
                <p className="form-description">
                    Enter the name of the server as it will appear
                    on this website. Pease keep this short.
                </p>
            </div>
            <div className="form-group">
                <label className="bold">Server Description</label>
                <p className="form-description">
                    Short description of the server. This will be
                    seen by everybody.
                </p>
                <textarea
                    rows={4}
                    placeholder="Enter short description of the server"
                    name="description"
                    value={ server.description || "" }
                    onChange={ e => onChange({ description: e.target.value }) }
                />
            </div>
            { !server.isLocal && <div className="checklist">
                <label className="checklist-title color-brand-dark">Visibility</label>
                <label className="checklist-item">
                    <input
                        type="radio"
                        name="visibility"
                        className="d-inline-block v-top"
                        checked={ !!server.public }
                        value={1}
                        onChange={ e => onChange({ public: e.target.checked }) }
                    /><div className="d-inline-block v-top">
                        <div>Public</div>
                        <div className="form-description" style={{ whiteSpace: "normal" }}>
                            Everybody can see and test this server.
                        </div>
                    </div>
                </label>
                <label className="checklist-item">
                    <input
                        type="radio"
                        name="visibility"
                        className="d-inline-block v-top"
                        checked={ !server.public }
                        value={0}
                        onChange={ e => onChange({ public: !e.target.checked }) }
                    /><div className="d-inline-block v-top">
                        <div>Private</div>
                        <div className="form-description" style={{ whiteSpace: "normal" }}>
                            Only I and the site administrator can see and test this server.
                        </div>
                    </div>
                </label>
            </div> }
        </div>
    )
}

function AuthenticationEditor({
    server,
    onChange
}: {
    server: Partial<Server>
    onChange: (data: NormalizedConfig) => void
}) {
    
    const authOptions = {
        type         : "none",
        privateKey   : null,
        jwksUrl      : undefined,
        clientSecret : null,
        optional     : true,
        tokenEndpoint: "",
        clientId     : "",
        ...server.settings?.authentication
    } as AuthenticationOptions
    
    const {
        type,
        privateKey,
        jwksUrl,
        clientSecret,
        optional,
        tokenEndpoint,
        clientId
    } = authOptions;

    const [ jwksString, setJwksString ] = useState(privateKey ? JSON.stringify(privateKey, null, 4) : "")

    const jwksHref = process.env.NODE_ENV === "development" ?
        `${require("../../../package.json").proxy}/jwks` :
        `${window.location.origin}/jwks`

    const changeAuth = (options: Partial<AuthenticationOptions>) => {
        onChange({
            ...server.settings,
            authentication: {
                ...authOptions,
                ...options
            }
        } as NormalizedConfig)
    }

    return (
        <>
            <div className="grid-col">
                <div className="checklist">
                    <div className="checklist-title color-brand-dark">Authentication Type</div>
                    <label className="checklist-item">
                        <input
                            type="radio"
                            name="auth-type"
                            className="d-inline-block v-top"
                            checked={ type === "backend-services" && !!jwksUrl }
                            value="backend-services"
                            onChange={ () => changeAuth({ type: "backend-services", jwksUrl: jwksHref }) }
                        /><div className="d-inline-block v-top">
                            <div><b>SMART Backend Services</b> - using JWKS URL</div>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Select this if you have chosen <code><a target="_blank" rel="noopener noreferrer"
                                href={jwksHref}><b>{jwksHref}</b></a></code> as JWKS URL at registration time.
                            </div>
                        </div>
                    </label>
                    <label className="checklist-item">
                        <input
                            type="radio"
                            name="auth-type"
                            className="d-inline-block v-top"
                            checked={ type === "backend-services" && !jwksUrl }
                            value="backend-services"
                            onChange={ () => changeAuth({ type: "backend-services", jwksUrl: undefined }) }
                        /><div className="d-inline-block v-top">
                            <div><b>SMART Backend Services</b> - using JWK Set</div>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Select this if you have supplied the JWK Set directly to the FHIR authorization
                                server at registration time.
                            </div>
                        </div>
                    </label>
                    
                    <label className="checklist-item">
                        <input
                            type="radio"
                            name="auth-type"
                            className="d-inline-block v-top"
                            checked={ type === "client-credentials" }
                            value="client-credentials"
                            onChange={ () => changeAuth({ type: "client-credentials" }) }
                        /><div className="d-inline-block v-top">
                            <div>OAuth Client Credentials</div>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Enter client ID and client secret to access pre-existing client
                            </div>
                        </div>
                    </label>
                    <label className="checklist-item">
                        <input
                            type="radio"
                            name="auth-type"
                            className="d-inline-block v-top"
                            checked={ type === "none" }
                            value="none"
                            onChange={ () => changeAuth({ type: "none" }) }
                        /><div className="d-inline-block v-top">
                            <div>None</div>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Select this for open servers that do not support any authentication
                            </div>
                        </div>
                    </label>
                    <br/>
                </div>
                <div className="checklist">
                    <div className="checklist-title color-brand-dark">Requires Authentication</div>
                    <label className="checklist-item">
                        <input
                            type="checkbox"
                            name="requiresAuth"
                            className="d-inline-block v-top"
                            checked={ !optional }
                            disabled={ type === "none" }
                            onChange={ e => changeAuth({ optional: !e.target.checked }) }
                        /><div className="d-inline-block v-top">
                            <div>This server cannot be used without authentication</div>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Un-check this if the authentication is optional for this server.
                            </div>
                        </div>
                    </label>
                    <br/>
                </div>
                <div className="checklist">
                    <div className="checklist-title color-brand-dark">SSL/TLS</div>
                    <label className="checklist-item">
                        <input
                            type="checkbox"
                            name="strictSSL"
                            className="d-inline-block v-top"
                            checked={ !server.settings?.requests?.strictSSL }
                            value={1}
                            onChange={ e => onChange({
                                ...server.settings,
                                // @ts-ignore
                                requests: {
                                    ...server.settings?.requests,
                                    strictSSL: !e.target.checked
                                }
                            }) }
                        /><div className="d-inline-block v-top">
                            <div>Uses self-signed certificate</div>
                            <div className="form-description" style={{ whiteSpace: "normal" }}>
                                Check this if needed to allow tests to accept self-signed certificates.
                            </div>
                        </div>
                    </label>
                    <br/>
                </div>
            </div>
            <div className="grid-col" style={{ display: type === "none" ? "none" : "block" }}>
                <div className="form-group">
                    <label className="bold" htmlFor="tokenEndpoint">Token Endpoint</label>
                    <input
                        id="tokenEndpoint"
                        type="url"
                        placeholder="Enter Token Endpoint"
                        disabled={ type === "none" }
                        required
                        value={ tokenEndpoint || "" }
                        onChange={ e => changeAuth({ tokenEndpoint: e.target.value }) }
                    />
                </div>
                <div className="form-group">
                    <label className="bold">Client ID</label>
                    <input
                        type="text"
                        placeholder="Enter Client ID"
                        disabled={ type === "none" }
                        required={ type !== "none" }
                        value={ clientId || "" }
                        onChange={ e => changeAuth({ clientId: e.target.value }) }
                    />
                </div>
                <div className="form-group" style={{ display: type === "client-credentials" ? "block" : "none" }}>
                    <label className="bold">Client Secret</label>
                    <textarea
                        rows={13}
                        placeholder="Enter Secret"
                        disabled={ type !== "client-credentials" }
                        required={ type === "client-credentials" }
                        value={ clientSecret || "" }
                        onChange={ e => changeAuth({ clientSecret: e.target.value }) }
                        style={{ whiteSpace: "pre-wrap" }}
                    />
                </div>
                <div className="form-group" style={{ display: type === "backend-services" ? "block" : "none" }}>
                    <label className="bold">Private Key</label>
                    <div className="form-description" style={{ whiteSpace: "normal" }}>
                        Please enter the private key as JWK here. If your key is in PEM format
                        put it in double quotes here, so that it is a valid JSON string value.
                    </div>
                    <textarea
                        style={{ fontFamily: "monospace", fontSize: 13, whiteSpace: "pre-wrap" }}
                        rows={14}
                        spellCheck={false}
                        value={ jwksString }
                        required={ type === "backend-services" }
                        disabled={ type !== "backend-services" }
                        onChange={ e => {
                            let json = e.target.value;
                            try {
                                if (!json.trim()) {
                                    changeAuth({ privateKey: undefined })
                                }
                                else {
                                    const parsed = JSON.parse(json);
                                    changeAuth({ privateKey: parsed });
                                }
                                e.target.setCustomValidity("");
                            } catch (ex) {
                                e.target.setCustomValidity("This is not a valid JSON");
                            }
                            setJwksString(json);
                        }}
                    />
                </div>
            </div>
        </>
    )
}

