import { useEffect, useRef } from "react"
import { JSONValue } from "../../.."


// @ts-ignore
window.require.config({ paths: { vs: "/monaco-editor/min/vs" }});


function createEditor(container: Element, onChange: (value: JSONValue) => void, value?: string) {

    return new Promise((resolve) => {
        // @ts-ignore
        window.require(["vs/editor/editor.main"], monaco => {
            const editor = monaco.editor.create(container, {
                value: value === undefined ? "" : value,
                language: "json",
                lineNumbers: "on",
                scrollBeyondLastLine: false,
                automaticLayout: true,
                emptySelectionClipboard: false,
                folding: true,
                fontFamily: "Menlo, monospace",
                fontSize: 14,
                glyphMargin: true,
                lineHeight: 18,
                mouseWheelZoom: true,
                renderLineHighlight: "all",
                minimap: {
                    enabled: false,
                    renderCharacters: false
                }
            });
                
            editor.onDidChangeModelContent(() => {
                try {
                    const json = JSON.parse(editor.getValue());
                    if (JSON.stringify(json) !== JSON.stringify(value)) {
                        onChange(json);
                    }
                } catch {
                    // ignore invalid json while editing
                }
            });

            // @ts-ignore
            container.editor = editor

            resolve(editor)
        })

    });
}

export function MonacoEditor({
    value,
    onChange
}: {
    value?: string
    onChange: (json: JSONValue) => void
})
{
    const editorNode = useRef(null);

    const editor = useRef<any>(null);

    useEffect(() => {

        if (editorNode.current) {
            createEditor(editorNode.current, onChange, value).then(ed => editor.current = ed)
        }

        return () => {
            if (editor.current) {
                editor.current.dispose()
            }
        }

        // eslint-disable-next-line
    }, []);

    return <div className="sql-editor" ref={editorNode} />
}
