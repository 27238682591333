import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { TestStatusMap, getTests, setAutoScroll } from "../../store/tests";
import "./Progress.scss"



export default function Progress() {
    const dispatch = useDispatch()

    const { tests, path, currentPath, autoScroll } = useSelector((state: RootState) => state.tests)

    const { selectedId } = useSelector((state: RootState) => state.servers)
    
    if (!selectedId) {
        return null
    }

    const scheduled = getTests(tests, path);

    const total = scheduled.length;

    const completed = scheduled.filter(t => t.endedAt).length;

    const pct = Math.floor((completed || 0) / (total || 1) * 100);

    const currentName = currentPath ? tests[currentPath].name : ""

    let message = currentName || "No tests were executed yet";

    if (pct === 100) {
        message = "All tests were executed"
    }
    else if (pct > 0 && !currentName) {
        message = "Testing was stopped"
    }

    const width = 1 / total * 100;

    const ticks = scheduled.map((test, index) => {
        const status = test.console?.find(e => e.type === "warn") ?
            "warned" : test.status || "unknown";
        const meta = TestStatusMap[status as keyof typeof TestStatusMap];
        return (
            <a 
                href={"#test-" + test.path}
                key={index} 
                className={ "progress-inner-tick _" + meta[0] }
                style={{ width : width + "%", left: width * index + "%" }}
                title={ test.name + ": \n\n" + meta[1]}
            > </a>
        );
    });

    return (
        <div className="col progress-wrapper">
            <div>
                <small className="pct"><label>
                Auto-scroll <input
                    type="checkbox"
                    checked={ autoScroll }
                    onChange={ e => dispatch(setAutoScroll(e.target.checked)) }
                />
                </label></small>
                <small className="msg">{ pct }% - { message }</small>
            </div>
            <div className="progress">
                { ticks }
            </div>
        </div>
    )
}
