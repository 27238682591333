import { useEffect, useRef } from "react"
import                "./Tooltip.scss"

export default function Tooltip({ html }: { html: string })
{
    const tooltip = useRef<any>(null)

    const updatePosition = () => 
    {
        const tdRect      = tooltip.current?.parentNode.getBoundingClientRect();
        const bodyRect    = document.documentElement.getBoundingClientRect();
        const tooltipRect = tooltip.current?.getBoundingClientRect();
        // console.log(tdRect, bodyRect, tooltipRect);

        if (tdRect.y + tdRect.height > bodyRect.height - 60 - tooltipRect.height) {
            tooltip.current?.classList.add("top");
        } else {
            tooltip.current?.classList.remove("top");
        }

        if (tdRect.x + tdRect.width / 2 > bodyRect.width - 10 - tooltipRect.width / 2) {
            tooltip.current?.classList.add("left");
        } else {
            tooltip.current?.classList.remove("left");
        }
    }

    useEffect(() => {
        let current = tooltip.current
        current?.parentNode.addEventListener("mouseover", updatePosition)
        return () => {
            current?.parentNode.removeEventListener("mouseover", updatePosition)
        }
    }, []);

    return (
        <div
            className="tooltip"
            dangerouslySetInnerHTML={{ __html: html }}
            ref={ tooltip }
        />
    )
}
