import { Dispatch } from "redux"
import { loadAvailableServers } from "./servers"

interface User {
    username: string
}

interface AuthState {
    loading        : boolean
    error          : Error | null
    currentUser    : User | null
    showLoginDialog: boolean
}

interface AuthActionSetLoading {
    type: "actions:auth:setLoading"
    payload: boolean
}

interface AuthActionShowLoginDialog {
    type: "actions:auth:showLoginDialog"
}

interface AuthActionHideLoginDialog {
    type: "actions:auth:hideLoginDialog"
}

interface AuthActionMerge {
    type: "actions:auth:merge"
    payload: Partial<AuthState>
}

type AnyAuthAction = AuthActionSetLoading |
    AuthActionShowLoginDialog |
    AuthActionHideLoginDialog |
    AuthActionMerge

const INITIAL_STATE: AuthState = {
    loading: false,
    error  : null,
    currentUser: JSON.parse(sessionStorage.currentUser || "null"),
    showLoginDialog: false
};


export function setLoading(payload: boolean): AuthActionSetLoading
{
    return { type: "actions:auth:setLoading", payload };
}

export function showLoginDialog(): AuthActionShowLoginDialog
{
    return { type: "actions:auth:showLoginDialog" };
}

export function hideLoginDialog(): AuthActionHideLoginDialog
{
    return { type: "actions:auth:hideLoginDialog" };
}

export function merge(payload: Partial<AuthState>): AuthActionMerge
{
    return { type: "actions:auth:merge", payload };
}

export function login(username: string, password: string)
{
    return function (dispatch: Dispatch<any>, getState: () => AuthState)
    {
        dispatch(setLoading(true));
        return fetch("/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
        })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                throw new Error(json.error);
            }
            return json;
        })
        .then(user => {
            sessionStorage.currentUser = JSON.stringify(user);
            dispatch(loadAvailableServers())
            dispatch(merge({
                currentUser    : user,
                error          : null,
                loading        : false,
                showLoginDialog: false
            }));
        })
        .catch(error => {
            dispatch(merge({
                error,
                loading: false
            }));
        });
    };
}

export function logout()
{
    return function (dispatch: Dispatch<any>, getState: () => AuthState)
    {
        dispatch(setLoading(true));
        return fetch("/auth/logout").then(res => {
            if (res.ok) {
                sessionStorage.removeItem("currentUser");
                dispatch(loadAvailableServers())
                dispatch(merge({
                    currentUser: null,
                    loading: false
                }));
            } else {
                dispatch(setLoading(false));
            }
        });
    };
}

export default function reducer(state = INITIAL_STATE, action: AnyAuthAction)
{
    switch (action.type) {
        case "actions:auth:merge":
            return { ...state, ...action.payload };
        case "actions:auth:setLoading":
            return { ...state, loading: !!action.payload };
        case "actions:auth:showLoginDialog":
            return { ...state, showLoginDialog: true };
        case "actions:auth:hideLoginDialog":
            return { ...state, showLoginDialog: false };
        default:
            return state;
    }
}

