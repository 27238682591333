import { useState }     from "react"
import { useDispatch }  from "react-redux"
import Form             from "../Servers/Form"
import { saveServer }   from "../../store/servers"
import { StoredServer } from "../../.."
import { request }      from "../../lib"


export function UpdateServerDialog({ close, server }: { close: () => void, server: StoredServer })
{
    const [ state, setState ] = useState<StoredServer>(server)
    const dispatch            = useDispatch()

    const save = async () => {
        if (!state.isLocal) {
            return request(`/api/servers/${state.id}`, {
                method : "PUT",
                body   : JSON.stringify(state),
                headers: { "content-type": "application/json" }
            })
            .then((server: StoredServer) => {
                dispatch(saveServer(server))
                close()
            })
        } else {
            dispatch(saveServer(state))
            close()
        }
    };

    return <Form
        server={ state }
        local={ false }
        onChange={ state => setState(state as StoredServer) }
        onSubmit={ () => save() }
        close={ close }
    />
}
