import { useEffect, useState } from "react"
import SyntaxHighlighter   from "react-syntax-highlighter"
// @ts-ignore
import { xcode }           from "react-syntax-highlighter/dist/styles/hljs"
import MarkdownIt          from "markdown-it"
import * as lib            from "../../lib"
import { runNode, TestsListItem, cancel, TestStatusMap } from "../../store/tests"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import "./Test.scss"


const md = MarkdownIt({
    breaks : true,
    linkify: true
});

function formatDuration(ms: number) {
    let out = [];
    let meta = [
        { label: "week"  , n: 1000 * 60 * 60 * 24 * 7 },
        { label: "day"   , n: 1000 * 60 * 60 * 24     },
        { label: "hour"  , n: 1000 * 60 * 60          },
        { label: "minute", n: 1000 * 60               },
        { label: "second", n: 1000                    },
        { label: "ms"    , n: 1                       }
    ];

    meta.reduce((prev, cur, i, all) => {
        let chunk = Math.floor(prev / cur.n);
        if (chunk) {
            out.push(`${chunk} ${cur.label}${chunk > 1 && cur.n > 1 ? "s" : ""}`);
            return prev - chunk * cur.n
        }
        return prev
    }, ms);

    if (!out.length) {
        out.push(`0 ${meta.pop()!.label}`);
    }

    if (out.length > 1) {
        let last = out.pop();
        out[out.length - 1] += " and " + last;
    }

    return out.join(", ")
}

function ellipsis(str: string, maxLen: number) {
    if (str.length > maxLen) {
        return str.substring(0, maxLen) + "...";
    }
    return str;
}

function TestStatusIcon({ test }: { test: TestsListItem }) {

    if (test.startedAt && !test.endedAt) {
        return <i className="fas fa-circle-notch fa-spin" style={{ color: TestStatusMap["running"][2] }} />
    }
    if (test.status === "succeeded") {
        return <i className="fas fa-check-circle" style={{ color: TestStatusMap["succeeded"][2] }} />
    }
    if (test.status === "failed") {
        return <i className="fas fa-times-circle" style={{ color: TestStatusMap["failed"][2] }} />
    }
    if (test.status === "not-implemented") {
        return <i className="fas fa-minus-circle" style={{ color: TestStatusMap["not-implemented"][2] }} />    
    }
    if (test.status === "not-supported") {
        return <i className="fas fa-minus-circle" style={{ color: TestStatusMap["not-supported"][2] }} />    
    }
    if (test.status === "warned") {
        return <i className="fas fa-exclamation-triangle" style={{ color: TestStatusMap["warned"][2] }} />    
    }
    if (test.status === "aborted") {
        return <i className="fas fa-times-circle" style={{ color: TestStatusMap["aborted"][2] }} />
    }
    if (test.status === "skipped") {
        return <i className="fas fa-minus-circle" style={{ color: TestStatusMap["skipped"][2] }} />    
    }
    return <i className="fas fa-flask" style={{ color: TestStatusMap["unknown"][2] }} />
}

function Description({ description }: { description?: string }) {
    return (
        <div className="test-info description">
            <span dangerouslySetInnerHTML={{
                __html: md.render(description || "*No description provided*")
                    .replace(/<a\s+href="([^"]*)">/g, '<a target="_blank" href="$1">')
            }}/>
        </div>
    );
}

function TestTiming({ test }: { test: TestsListItem }) {
    if (test.startedAt && test.endedAt) {
        return (
            <>
                <span className="text-muted"> &nbsp; | &nbsp; </span>Executing this test took: <b>
                { formatDuration(Math.max(+test.endedAt - +test.startedAt, 0)) }</b>.
            </>
        );
    }
    return null
}

function TestError({ test }: { test: TestsListItem }) {
    if (test.error) {
        return (
            <div className="test-info error">
            { JSON.stringify(test.error, null, 4) }
            </div>
         );
    }
    return null;
}

function RequestDecoration({ method, url, headers, payload }: { method: string, url: string, headers: Record<string, any>, payload: any }) {
    return (
        <pre>
            <b style={{ color: "#630" }} className="force-wrap">{method} {url}</b>
            <table style={{ maxWidth: "100%" }}>
                <tbody>
                { Object.keys(headers).map(key => (
                    <tr key={key}>
                        <th>{key}: </th>
                        <td>{ JSON.stringify(headers[key]) }</td>
                    </tr>
                )) }
                </tbody>
            </table>
            <HttpBody body={payload} headers={headers} />
        </pre>
    );
}

function HttpBody({ body, headers }: { body: any, headers: Record<string, any> }) {
    if (!body) {
        return null;
    }

    let bodyComponent;

    const contentType = String(headers["content-type"] || "");
    
    if (contentType.match(/\bhtml\b/)) {
        bodyComponent = <SyntaxHighlighter language="html" style={xcode} customStyle={{
            background: "transparent",
            padding: "2px 0 8px"
        }}>{ellipsis(body, 5000)}</SyntaxHighlighter>
    }
    else if (contentType.match(/\bxml\b/)) {
        bodyComponent = <SyntaxHighlighter language="xml" style={xcode} customStyle={{
            background: "transparent",
            padding: "2px 0 8px"
        }}>{ellipsis(body, 5000)}</SyntaxHighlighter>
    }
    else if (contentType.match(/\bndjson\b/)) {
        bodyComponent = <SyntaxHighlighter language="ndjson" style={xcode} customStyle={{
            background: "transparent",
            padding: "2px 0 8px"
        }}>{ellipsis(body, 5000)}</SyntaxHighlighter>
    }
    else if (contentType.match(/\bjson\b/)) {
        let json;
        try {
            if (typeof body == "string") {
                json = JSON.parse(body);
            } else {
                json = body;
            }
            json = JSON.stringify(json, null, 4);
        } catch (ex) {
            json = String(body);
        }
        bodyComponent = <SyntaxHighlighter language="json" style={xcode} customStyle={{
            background: "transparent",
            padding: "2px 0 8px"
        }}>{ellipsis(json, 5000)}</SyntaxHighlighter>
    }
    else if (contentType.indexOf("application/x-www-form-urlencoded") === 0) {
        bodyComponent = <table style={{ maxWidth: "100%" }}>
            <tbody>
                { Object.keys(body).map((k, i) => (
                    <tr key={i}>
                        <th>{k}:</th>
                        <td>{JSON.stringify(body[k])}</td>
                    </tr>    
                ))}
            </tbody>
        </table>
    }
    else {
        bodyComponent = <SyntaxHighlighter language="http" style={xcode} customStyle={{
            background: "transparent",
            padding: "2px 0 8px"
        }}>{ellipsis(body, 5000)}</SyntaxHighlighter>
    }

    return (
        <div>
            <p style={{
                color: "#888",
                boxShadow: "0 1px 0 rgba(0,0,0,0.2), 0 2px 0 #FFF"
            }}><b>BODY:</b></p>
            { bodyComponent }
        </div>
    );
}

function ResponseDecoration({ statusCode, statusMessage, headers, body }: { statusCode: number, statusMessage: string, headers: Record<string, string>, body: any }) {
    return (
        <pre>
            <b style={{ color: "#630" }}>{statusCode} {statusMessage}</b>
            <table style={{ maxWidth: "100%" }}>
                <tbody>
                { Object.keys(headers).map(key => (
                    <tr key={key}>
                        <th>{key}: </th>
                        <td>{ JSON.stringify(headers[key]) }</td>
                    </tr>
                )) }
                </tbody>
            </table>
            <HttpBody body={body} headers={headers} />
        </pre>
    );
}

function TestDecoration({ label, value }: { label: string, value: any }) {
    let [opened, setOpened] = useState(false);

    if (value.type === "warn") {
        return <div className="test-info warning" dangerouslySetInnerHTML={{
            __html: md.render(value.data[0] || "").replace(/<a\s+href="([^"]*)">/g, '<a target="_blank" href="$1">')
        }}/>;
    }

    if (value.type === "info") {
        return <div className="test-info info" dangerouslySetInnerHTML={{
            __html: md.render(value.data[0] || "").replace(/<a\s+href="([^"]*)">/g, '<a target="_blank" href="$1">')
        }}/>;
    }

    if (value.type === "error") {
        return <div className="test-info error" dangerouslySetInnerHTML={{
            __html: md.render(value.data[0] || "").replace(/<a\s+href="([^"]*)">/g, '<a target="_blank" href="$1">')
        }}/>;
    }

    function renderDecoration(value: any) {
        if (value.tags.includes("request")) {
            return <RequestDecoration { ...value.data[0] } />;
        }

        if (value.tags.includes("response")) {
            return <ResponseDecoration { ...value.data[0] } />;
        }        
        
        return (
            <SyntaxHighlighter language="json" style={xcode} customStyle={{
                display: opened ? "block" : "none",
                background: "transparent",
                padding: "2px 0 8px"
            }}>{JSON.stringify(value, null, 2)}</SyntaxHighlighter>
        );
    }

    return (
        <div className="test-info">
            <div onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
                <b><i className={"fas fa-caret-" + (opened ? "down" : "right")}/> {label}</b>
            </div>
            <div style={{
                display: opened ? "block" : "none",
                padding: "0 0 0 28px"
            }}>
                { renderDecoration(value) }
            </div>
        </div>
    );
}

function TestConsole({ test }: { test: TestsListItem }) {
    if (test.console) {
        return (
            <>
                { test.console.map((item, i) => (
                    <TestDecoration key={i} label={item.label} value={item}/>
                )) }
            </>
        );
    }
    return null;
}

function TestInfo({ test }: { test: TestsListItem }) {
    let version = test.minVersion || "1.0";
    if (test.maxVersion) {
        version += ` to ${test.maxVersion}`;
    } else {
        version += "+";
    }
    return (
        <div className="children small text-muted">
            <Description description={ test.description }/>
            <TestConsole test={test}/>
            <TestError test={test}/>
            <div className="test-info footer">
                Bulk Data API Version: <b>{version}</b><span className="text-muted"> &nbsp; | &nbsp; </span>
                Test ID: <b>{test.id}</b><span className="text-muted"> &nbsp; | &nbsp; </span>
                Test path: <b>{ test.path }</b>
                <TestTiming test={ test }/>
            </div>
        </div>
    );
}

export default function Test({ item }: { item: TestsListItem }) {
    const [viewType, setViewType] = useState("none");

    const { autoScroll, running, path } = useSelector((state: RootState) => state.tests)
    const { selectedId } = useSelector((state: RootState) => state.servers)

    const dispatch = useDispatch<Dispatch<any>>()

    useEffect(() => {
        if (autoScroll && path === "" && item.startedAt && !item.endedAt) {
            document.getElementById("test-" + item.path)?.scrollIntoView({
                block: "center",
                behavior: "smooth"
            });
        }
    });

    return (
        <div id={"test-" + item.path} tabIndex={0} className={
            lib.className({
                test: 1,
                opened: viewType !== "none",
                closed: viewType === "none",
                [item.status!]: 1,
                // hidden: !lib.matchesVersion(item, bulkDataVersion)
            })
        }>
            <header className="test-header" onMouseDown={ () => setViewType(viewType === "info" ? "none" : "info") }>
                <TestStatusIcon test={ item } />
                <span className="name"> { item.name }</span>
                { !running && <span className="run-button" onMouseDown={ (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    dispatch(runNode(item.path, selectedId!));
                }}>Run ▶︎</span> }
                { item.startedAt && !item.endedAt && <span className="run-button" onMouseDown={ (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    dispatch(cancel());
                }}>Cancel ■</span> }
            </header>
            { viewType === "info" && <TestInfo test={item} /> }
        </div>
    )
}
